import "vuetify/styles";
import { createVuetify } from "vuetify";

const ebut = {
	dark: false,
	colors: {
		background: "#FFFFFF",
		surface: "#FFFFFF",
		primary: "#1C1C1C",
		"primary-1": "#494949",
		"primary-2": "#8D8D8D",
		"primary-3": "#BABABA",
		"primary-4": "#D1D1D1",
		"primary-5": "#F2F2F2",
		"primary-6": "#F4F4F4",
		secondary: "#FD4452",
		"secondary-1": "#FD6974",
		"secondary-2": "#FEA1A8",
		"secondary-3": "#FFD9DC",
		tertiary: "#3860CC",
		"tertiary-1": "#5F7FD6",
		"tertiary-2": "#9BAFE5",
		"tertiary-3": "#D7DFF5",
		white: "#ffffff",
		error: "#dd0a11",
		info: "#4d63e8",
		success: "#40c256",
		warning: "#dc6c2f",
	},
	variables: {
		// 'medium-emphasis-opacity': 1 // Definicja wartości zmiennej w v-root
	},
};

const vuetify = createVuetify({
	theme: {
		defaultTheme: "ebut",
		themes: {
			ebut: ebut,
		},
	},
	// locale: {
	// 	defaultLocale: LocaleService.getLocale(),
	// 	fallbackLocale: LocaleService.getDefaultLocale(),
	// 	message: {
	// 		pl, en
	// 	}
	// }
});

export default vuetify;

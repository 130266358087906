import { createRouter, createWebHistory } from "vue-router";
import MiddlewareController from "@/middlewares/MiddlewareController.js";
import LocaleService from "@/services/LocaleService";
import { setLocale } from "yup";
import i18n from "@/i18n";
import routes from "./general";
import messages from "@/lang";
import { useLoadingStore } from "@/stores/useLoadingStore";
import CurrencyService from "@/services/CurrencyService";
import SEOService from "@/services/SEOService";

const router = createRouter({
	history: createWebHistory(),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		if ("false" === to.query.scrollBehavior || "0" === to.query.scrollBehavior) return savedPosition;

		if (to.hash) return { el: to.hash };
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	const loadingStore = useLoadingStore();
	loadingStore.routeStart(to.name);

	SEOService.removeCanonical();

	const middlewareNext = MiddlewareController({
		to,
		from,
		next,
	});
	if (!!middlewareNext) {
		return middlewareNext();
	}

	return next();
});

router.afterEach((to, from) => {
	LocaleService.saveLocale(to.params.locale);
	i18n.global.locale.value = LocaleService.getLocale();

	if (messages[LocaleService.getLocale()]["formValidate"]["yup"]) {
		setLocale(messages[LocaleService.getLocale()]["formValidate"]["yup"]);
	}

	CurrencyService.saveCurrency("pln");

	SEOService.controller(to);

	const loadingStore = useLoadingStore();
	loadingStore.routeStop(to.name);
});

export default router;

import SanctumMiddleware from "@/middlewares/SanctumMiddleware";
import RouteExistsMiddleware from "@/middlewares/RouteExistsMiddleware";
import LocaleExistsMiddleware from "@/middlewares/LocaleExistsMiddleware";
import AuthPassedMiddleware from "@/middlewares/AuthPassedMiddleware";
import ShopVerifyPassedMiddleware from "@/middlewares/ShopVerifyPassedMiddleware";
import ShopIntroFailedMiddleware from "@/middlewares/ShopIntroFailedMiddleware";
import ShopIntroPassedMiddleware from "@/middlewares/ShopIntroPassedMiddleware";
import RoutesService from "@/services/RoutesService";
import errorRoutes from "./error";
import authRoutes from "./auth";
import adminRoutes from "./admin";
import shopRoutes from "./shop";
import customerRoutes from "./customer";

const routes = [
	{
		path: "",
		name: "index",
		redirect: {
			name: "mainPage",
			params: {
				locale: "pl",
			},
		},
	},
	{
		path: `/:locale`,
		component: () => import("@/views/Index.vue"),
		meta: {
			middleware: [SanctumMiddleware, LocaleExistsMiddleware],
		},
		children: [
			{
				name: "home",
				path: "",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "mainPage",
				},
				children: [
					{
						name: "mainPage",
						path: "",
						component: () => import("@/views/pages/main_page/MainPage.vue"),
						meta: {
							layout: "mainPage",
						},
					},
					{
						name: "admin",
						path: "admin",
						component: () => import("@/views/layouts/content/MenuTopTwoColsContentLayout.vue"),
						meta: {
							layout: "default",
							defaultLayout: {
								footer: "primary",
							},
							middleware: [AuthPassedMiddleware],
							breadcrumbs: {
								hidden: true,
							},
						},
						redirect: {
							name: "admin.index",
						},
						children: [
							{
								name: "admin.index",
								path: "",
								redirect: {
									name: "admin.products.index",
								},
								components: {
									default: () => import("@/views/Blank.vue"),
									aside: () => import("@/views/pages/admin/AdminMenuAside.vue"),
									menuTop: () => import("@/views/pages/admin/AdminMenuTop.vue"),
								},
								children: [...adminRoutes],
							},
						],
					},
					{
						name: "shop.intro",
						path: "shop/intro",
						component: () => import("@/views/pages/shop/intro/ShopIntro.vue"),
						meta: {
							middleware: [AuthPassedMiddleware, ShopVerifyPassedMiddleware, ShopIntroFailedMiddleware],
							menuTopTwoColsContentLayout: {
								aside: false,
								menuTop: false,
							},
						},
					},
					{
						name: "shop",
						path: "shop",
						component: () => import("@/views/layouts/content/MenuTopTwoColsContentLayout.vue"),
						meta: {
							layout: "default-shop",
							middleware: [AuthPassedMiddleware],
							breadcrumbs: {
								hidden: true,
							},
						},
						redirect: {
							name: "shop.index2",
						},
						children: [
							{
								name: "shop.index",
								path: "",
								components: {
									default: () => import("@/views/Blank.vue"),
									aside: () => import("@/views/pages/shop/ShopMenuAside.vue"),
									menuTop: () => import("@/views/pages/shop/ShopMenuTop.vue"),
								},
								meta: {
									breadcrumbs: {
										hidden: true,
									},
								},
								children: [...shopRoutes],
							},
						],
					},
					{
						name: "shop.warehouse.list",
						path: "shop/warehouse/list",
						component: () => import("@/views/layouts/content/MenuTopTwoColsContentLayout.vue"),
						meta: {
							layout: "default-shop",
							middleware: [AuthPassedMiddleware, ShopVerifyPassedMiddleware, ShopIntroPassedMiddleware],
							breadcrumbs: {
								hidden: true,
							},
						},
						redirect: {
							name: "shop.warehouse.list.index",
						},
						children: [
							{
								name: "shop.warehouse.list.index",
								path: "",
								components: {
									contentBottom: () => import("@/views/pages/shop/warehouse/list/ShopWarehouseList.vue"),
									aside: () => import("@/views/pages/shop/warehouse/list/ShopWarehouseListMenuAside.vue"),
									asideBottom: () => import("@/views/pages/shop/warehouse/list/ShopWarehouseListMenuAsideBottom.vue"),
									menuTop: () => import("@/views/pages/shop/warehouse/list/ShopWarehouseListMenuTop.vue"),
									default: () => import("@/views/pages/shop/warehouse/list/ShopWarehouseListFilterSearch.vue"),
								},
								meta: {
									menuTopTwoColsContentLayout: {
										contentBottom: true,
										asideBottom: true,
									},
								},
							},
						],
					},

					{
						name: "shop.promotions.list",
						path: "shop/promotions/list",
						component: () => import("@/views/layouts/content/MenuTopTwoColsContentLayout.vue"),
						meta: {
							layout: "default-shop",
							middleware: [AuthPassedMiddleware, ShopVerifyPassedMiddleware, ShopIntroPassedMiddleware],
							breadcrumbs: {
								hidden: true,
							},
						},
						redirect: {
							name: "shop.promotions.list.index",
						},
						children: [
							{
								name: "shop.promotions.list.index",
								path: "",
								components: {
									contentBottom: () => import("@/views/pages/shop/promotions/list/ShopPromotionsList.vue"),
									aside: () => import("@/views/pages/shop/promotions/list/ShopPromotionsListMenuAside.vue"),
									asideBottom: () => import("@/views/pages/shop/promotions/list/ShopPromotionsListMenuAsideBottom.vue"),
									menuTop: () => import("@/views/pages/shop/promotions/list/ShopPromotionsListMenuTop.vue"),
									default: () => import("@/views/pages/shop/promotions/list/ShopPromotionsListFilterSearch.vue"),
								},
								meta: {
									menuTopTwoColsContentLayout: {
										contentBottom: true,
										asideBottom: true,
									},
								},
							},
						],
					},

					{
						name: "customer",
						path: "customer",
						component: () => import("@/views/layouts/content/MenuTopTwoColsContentLayout.vue"),
						meta: {
							layout: "default",
							defaultLayout: {
								footer: "primary",
							},
							middleware: [AuthPassedMiddleware],
							breadcrumbs: {
								hidden: true,
							},
						},
						redirect: {
							name: "customer.index",
						},
						children: [
							{
								name: "customer.index",
								path: "",
								redirect: {
									name: "customer.myEbut",
								},
								components: {
									default: () => import("@/views/Blank.vue"),
									aside: () => import("@/views/pages/customer/CustomerMenuAside.vue"),
									menuTop: () => import("@/views/pages/customer/CustomerMenuTop.vue"),
								},
								meta: {
									breadcrumbs: {
										hidden: true,
									},
								},
								children: [...customerRoutes],
							},
						],
					},
					{
						name: "route.page.static",
						path: "",
						component: () => import("@/views/pages/route_static_page/RouteStaticPage.vue"),
						meta: {
							layout: "page",
						},
						children: [
							{
								name: "regulations",
								path: "regulations",
								component: () => import("@/views/pages/route_static_page/RouteStaticPageRegulations.vue"),
							},
							{
								name: "privacyPolicy",
								path: "privacy-policy",
								component: () => import("@/views/pages/route_static_page/RouteStaticPagePrivacyPolicy.vue"),
							},
						],
					},
					{
						name: "sizesChart",
						path: "sizes-chart/:sizesChartId?",
						component: () => import("@/views/pages/sizes_chart/SizesChart.vue"),
						meta: {
							layout: "list",
						},
					},
					{
						name: "brands",
						path: "marki",
						component: () => import("@/views/pages/brands/BrandsList.vue"),
						meta: {
							layout: "list",
						},
					},
					{
						name: "shops",
						path: "sklepy",
						component: () => import("@/views/Blank.vue"),
						redirect: {
							name: "shops.list",
						},
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "shops.list",
								path: "",
								component: () => import("@/views/pages/shops/ShopsList.vue"),
								meta: {
									layout: "list",
								},
							},
							{
								name: "shops.preview",
								path: ":shopSlug?",
								component: () => import("@/views/pages/shops/ShopsPreview.vue"),
								meta: {
									layout: "default-compact",
									defaultLayout: {
										footer: "primary",
									},
									menuTopTwoColsContentLayout: {
										aside: false,
									},
								},
							},
						],
					},
					{
						name: RoutesService.routes.offersRoute.name,
						path: RoutesService.routes.offersRoute.path,
						component: () => import("@/views/Blank.vue"),
						meta: {
							layout: "offers",
						},
						redirect: {
							name: "offers.list2",
						},
						children: [
							{
								name: "offers.index",
								path: "",
								component: () => import("@/views/layouts/content/TwoColsContentLayout.vue"),
								meta: {
									layout: "offers",
									breadcrumbs: {
										hidden: true,
									},
								},
								redirect: {
									name: "offers.list",
								},
								children: [
									{
										name: "offers.list",
										path: ":filters*",
										components: {
											default: () => import("@/views/pages/offers/list/OffersList.vue"),
											aside: () => import("@/views/pages/offers/list/filters/OffersListFiltersLeft.vue"),
											beforeCols: () => import("@/views/pages/offers/list/OffersListTop.vue"),
											colsSwitcher: () => import("@/views/pages/offers/list/OffersListColumnSwitcher.vue"),
										},
										meta: {
											breadcrumbs: {
												hidden: true,
											},
										},
									},
								],
							},
						],
					},
					{
						name: "offers.preview",
						path: "oferta/:offerSlug?",
						component: () => import("@/views/pages/offers/offer_preview/OfferPreview.vue"),
						meta: {
							layout: "offer",
						},
					},
					{
						name: "page.static",
						path: ":slug",
						component: () => import("@/views/pages/StaticPage.vue"),
						meta: {
							layout: "page",
						},
					},
				],
			},

			{
				name: "auth",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					layout: "clear",
				},
				children: [...authRoutes],
			},

			// {
			// 	name: 'demo',
			// 	path: 'demo',
			// 	component: () => import('@/views/layouts/content/MenuTopTwoColsContentLayout.vue'),
			// 	meta: {
			// 		layout: 'default'
			// 	},
			// 	children: [
			// 		{
			// 			name: 'demo2.index',
			// 			path: '',
			// 			components: {
			// 				default: () => import('@/views/pages/demo/DemoComponents.vue'),
			// 				aside: () => import('@/views/pages/demo/DemoMenuAside.vue'),
			// 				menuTop: () => import('@/views/pages/demo/DemoMenuTop.vue')
			// 			}
			// 		}
			// 	]
			// },

			{
				name: "errors",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					layout: "mainPage",
				},
				children: [...errorRoutes],
			},
		],
	},
	{
		path: "/:path(.*)*",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [SanctumMiddleware, LocaleExistsMiddleware, RouteExistsMiddleware],
		},
	},
];

export default routes;

import RepositoryService from "@/services/RepositoryService";
const resource = 'auth';

export const check = () => {
	return RepositoryService.getRaw(`${resource}/me`, {
		with_agreements: 1
	});
}

export const getNotifications = () => {
	return RepositoryService.getRaw(`${resource}/me/notifications`);
}

export const login = (credentials) => {
	return RepositoryService.post(`${resource}/login`, {
		email: credentials.email,
		password: credentials.password,
		remember: credentials.remember
	});
}

export const logout = () => {
	return RepositoryService.post(`${resource}/logout`, );
}

export const passwordForgot = (data) => {
	return RepositoryService.post(`${resource}/password-forget`, {
		email: data.email
	});
}

export const passwordReset = (data) => {
	return RepositoryService.post(`${resource}/password-reset`, {
		token: data.token,
		email: data.email,
		password: data.password,
		password_confirmation: data.password_confirmation
	});
}

export const update = (data) => {
	return RepositoryService.put(`${resource}/me`, data);
}

export const updateNotifications = (data) => {
	return RepositoryService.put(`${resource}/me/notifications`, data);
}

export const deleteAccount = () => {
	return RepositoryService.del(`${resource}/me`);
}


export default { check, login, logout, passwordForgot, passwordReset, update, getNotifications, updateNotifications, deleteAccount}
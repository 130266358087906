import {useAuthStore} from "@/stores/useAuthStore";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";
import RolesService from "@/services/RolesService";
import LocaleService from "@/services/LocaleService";

export const ShopIntroFailedMiddleware = ({to, next}) => {
	const locale = to.params.locale? to.params.locale : LocaleService.getLocale();

	if (!RolesService.isShop()) {
		return RouterService.push({ name: RoutesService.routes.home, params: { locale: locale } });
	}

	if (!useAuthStore().getUser.force_onboarding) {
		return RouterService.push({ name: RoutesService.routes.shop, params: { locale: locale } });
	}

	return next();
}

export default ShopIntroFailedMiddleware;
import { permissions } from "@/services/PermissionsService";

const adminRoutes = [
	{
		name: "admin.users.index",
		path: "users",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.users.list",
		},
		meta: {
			permissions: permissions.USERS_PREVIEW,
		},
		children: [
			{
				name: "admin.users.list",
				path: "list",
				component: () => import("@/views/pages/admin/users/AdminUsersList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.users.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/users/AdminUsersEdit.vue"),
				meta: {
					permissions: permissions.USERS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.shops.index",
		path: "shops",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.shops.list",
		},
		meta: {
			permissions: permissions.SHOP_USERS_PREVIEW,
		},
		children: [
			{
				name: "admin.shops.list",
				path: "list",
				component: () => import("@/views/pages/admin/shops/AdminShopsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.shops.show",
				path: "show/:id?",
				component: () => import("@/views/pages/admin/shops/AdminShopsShow.vue"),
				meta: {
					permissions: permissions.SHOP_USERS_PREVIEW,
					menuRoute: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.shops.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/shops/AdminShopsEdit.vue"),
				meta: {
					permissions: permissions.SHOP_USERS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.pages.index",
		path: "pages",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.pages.list",
		},
		meta: {
			permissions: permissions.PAGES_PREVIEW,
		},
		children: [
			{
				name: "admin.pages.list",
				path: "list",
				component: () => import("@/views/pages/admin/pages/AdminPagesList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.pages.add",
				path: "add",
				component: () => import("@/views/pages/admin/pages/AdminPagesAdd.vue"),
				meta: {
					permissions: permissions.PAGES_MANAGE,
				},
			},
			{
				name: "admin.pages.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/pages/AdminPagesEdit.vue"),
				meta: {
					permissions: permissions.PAGES_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.sales.promotions.index",
		path: "promotions",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.sales.promotions.list",
		},
		meta: {
			permissions: permissions.ADMIN_SALES_MANAGE,
		},
		children: [
			{
				name: "admin.sales.promotions.list",
				path: "list",
				component: () => import("@/views/pages/admin/sales/promotions/AdminSalesPromotionsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.sales.promotions.preview",
				path: "preview/:id?",
				component: () => import("@/views/pages/admin/sales/promotions/AdminSalesPromotionsPreview.vue"),
				meta: {
					permissions: permissions.ADMIN_SALES_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.menuPages.index",
		path: "menu-pages",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.menuPages.list",
		},
		meta: {
			permissions: permissions.PAGES_MANAGE,
		},
		children: [
			{
				name: "admin.menuPages.list",
				path: "list",
				component: () => import("@/views/pages/admin/menu_pages/AdminMenuPagesList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.menuPages.add",
				path: "add",
				component: () => import("@/views/pages/admin/menu_pages/AdminMenuPagesAdd.vue"),
			},
			{
				name: "admin.menuPages.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/menu_pages/AdminMenuPagesEdit.vue"),
				meta: {
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.otherPagesContent.index",
		path: "other-pages",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.otherPagesContent.list",
		},
		meta: {
			permissions: permissions.PAGES_MANAGE,
		},
		children: [
			{
				name: "admin.otherPagesContent.list",
				path: "list",
				component: () => import("@/views/pages/admin/other_pages_content/AdminOtherPagesContentList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.otherPagesContent.edit",
				path: "edit/:type",
				component: () => import("@/views/pages/admin/other_pages_content/AdminOtherPagesContentEdit.vue"),
				meta: {
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.brands.index",
		path: "brands",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.brands.list",
		},
		meta: {
			permissions: permissions.BRANDS_PREVIEW,
		},
		children: [
			{
				name: "admin.brands.list",
				path: "list",
				component: () => import("@/views/pages/admin/brands/AdminBrandsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.brands.add",
				path: "add",
				component: () => import("@/views/pages/admin/brands/AdminBrandsAdd.vue"),
				meta: {
					permissions: permissions.BRANDS_MANAGE,
				},
			},
			{
				name: "admin.brands.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/brands/AdminBrandsEdit.vue"),
				meta: {
					permissions: permissions.BRANDS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.sizesCharts.index",
		path: "sizes-charts",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.sizesCharts.list",
		},
		meta: {
			permissions: permissions.BRANDS_PREVIEW,
		},
		children: [
			{
				name: "admin.sizesCharts.list",
				path: "list",
				component: () => import("@/views/pages/admin/sizes_charts/AdminSizesChartsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.sizesCharts.add",
				path: "add",
				component: () => import("@/views/pages/admin/sizes_charts/AdminSizesChartsAdd.vue"),
				meta: {
					permissions: permissions.BRANDS_MANAGE,
				},
			},
			{
				name: "admin.sizesCharts.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/sizes_charts/AdminSizesChartsEdit.vue"),
				meta: {
					permissions: permissions.BRANDS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.settings.index",
		path: "settings",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.settings.smtp",
		},
		meta: {
			permissions: permissions.SETTINGS_PREVIEW,
		},
		children: [
			{
				name: "admin.settings.smtp",
				path: "smtp",
				component: () => import("@/views/pages/admin/settings/AdminSettingsSMTPForm.vue"),
				meta: {
					permissions: permissions.SETTINGS_MANAGE,
				},
			},
			{
				name: "admin.settings.socialMedia",
				path: "socialMedia",
				component: () => import("@/views/pages/admin/settings/AdminSettingsSocialMediaForm.vue"),
				meta: {
					permissions: permissions.SETTINGS_MANAGE,
				},
			},
		],
	},
	{
		name: "admin.colors.index",
		path: "colors",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.colors.list",
		},
		meta: {
			permissions: permissions.PRODUCTS_PREVIEW,
		},
		children: [
			{
				name: "admin.colors.list",
				path: "list",
				component: () => import("@/views/pages/admin/colors/AdminColorsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.colors.add",
				path: "add",
				component: () => import("@/views/pages/admin/colors/AdminColorsAdd.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
				},
			},
			{
				name: "admin.colors.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/colors/AdminColorsEdit.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.seasons.index",
		path: "seasons",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.seasons.list",
		},
		meta: {
			permissions: permissions.PRODUCTS_PREVIEW,
		},
		children: [
			{
				name: "admin.seasons.list",
				path: "list",
				component: () => import("@/views/pages/admin/seasons/AdminSeasonsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.seasons.add",
				path: "add",
				component: () => import("@/views/pages/admin/seasons/AdminSeasonsAdd.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
				},
			},
			{
				name: "admin.seasons.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/seasons/AdminSeasonsEdit.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.payments.index",
		path: "payments",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.payments.list",
		},
		meta: {
			permissions: permissions.PAYMENT_METHODS_PREVIEW,
		},
		children: [
			{
				name: "admin.payments.list",
				path: "list",
				component: () => import("@/views/pages/admin/payments/AdminPaymentsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.payments.add",
				path: "add",
				component: () => import("@/views/pages/admin/payments/AdminPaymentsAdd.vue"),
				meta: {
					permissions: permissions.PAYMENT_METHODS_MANAGE,
				},
			},
			{
				name: "admin.payments.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/payments/AdminPaymentsEdit.vue"),
				meta: {
					permissions: permissions.PAYMENT_METHODS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.upperMaterial.index",
		path: "upper-material",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.upperMaterial.list",
		},
		meta: {
			permissions: permissions.PRODUCTS_PREVIEW,
		},
		children: [
			{
				name: "admin.upperMaterial.list",
				path: "list",
				component: () => import("@/views/pages/admin/upper_material/AdminUpperMaterialList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.upperMaterial.add",
				path: "add",
				component: () => import("@/views/pages/admin/upper_material/AdminUpperMaterialAdd.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
				},
			},
			{
				name: "admin.upperMaterial.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/upper_material/AdminUpperMaterialEdit.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.fastens.index",
		path: "fastens",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.fastens.list",
		},
		meta: {
			permissions: permissions.PRODUCTS_PREVIEW,
		},
		children: [
			{
				name: "admin.fastens.list",
				path: "list",
				component: () => import("@/views/pages/admin/fastens/AdminFastensList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.fastens.add",
				path: "add",
				component: () => import("@/views/pages/admin/fastens/AdminFastensAdd.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
				},
			},
			{
				name: "admin.fastens.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/fastens/AdminFastensEdit.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.advertisementBanners.index",
		path: "advertisement-banners",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.advertisementBanners.list",
		},
		meta: {
			permissions: permissions.PAGES_PREVIEW,
		},
		children: [
			{
				name: "admin.advertisementBanners.list",
				path: "list",
				component: () => import("@/views/pages/admin/advertisement_banners/AdminAdvertisementBannersList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.advertisementBanners.add",
				path: "add",
				component: () => import("@/views/pages/admin/advertisement_banners/AdminAdvertisementBannersAdd.vue"),
				meta: {
					permissions: permissions.PAGES_MANAGE,
				},
			},
			{
				name: "admin.advertisementBanners.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/advertisement_banners/AdminAdvertisementBannersEdit.vue"),
				meta: {
					permissions: permissions.PAGES_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.productCategories.index",
		path: "product-categories",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.productCategories.list",
		},
		meta: {
			permissions: permissions.PRODUCT_CATEGORIES_PREVIEW,
		},
		children: [
			{
				name: "admin.productCategories.list",
				path: "list",
				component: () => import("@/views/pages/admin/product_categories/AdminProductCategoriesList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.productCategories.add",
				path: "add",
				component: () => import("@/views/pages/admin/product_categories/AdminProductCategoriesAdd.vue"),
				meta: {
					permissions: permissions.PRODUCT_CATEGORIES_MANAGE,
				},
			},
			{
				name: "admin.productCategories.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/product_categories/AdminProductCategoriesEdit.vue"),
				meta: {
					permissions: permissions.PRODUCT_CATEGORIES_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.productCategories.attributes",
				path: "attributes/:productCategoryId",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "admin.productCategories.attributes.list",
				},
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
					breadcrumbs: {
						hidden: true,
					},
					menuRoute: {
						hidden: true,
					},
				},
				children: [
					{
						name: "admin.productCategories.attributes.list",
						path: "list",
						component: () => import("@/views/pages/admin/product_categories/AdminProductCategoriesAttributesList.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
					},
					{
						name: "admin.productCategories.attributes.add",
						path: "add",
						component: () => import("@/views/pages/admin/product_categories/AdminProductCategoriesAttributesAdd.vue"),
						menuRoute: {
							hidden: true,
						},
					},
					{
						name: "admin.productCategories.attributes.edit",
						path: "edit/:id?",
						component: () => import("@/views/pages/admin/product_categories/AdminProductCategoriesAttributesEdit.vue"),
						breadcrumbs: {
							hidden: true,
						},
						menuRoute: {
							hidden: true,
						},
					},
				],
			},
		],
	},
	{
		name: "admin.products.index",
		path: "products",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.products.list",
		},
		meta: {
			permissions: permissions.PRODUCTS_PREVIEW,
		},
		children: [
			{
				name: "admin.products.list",
				path: "list",
				component: () => import("@/views/pages/admin/products/AdminProductsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.products.add",
				path: "add",
				component: () => import("@/views/pages/admin/products/AdminProductsAdd.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
				},
			},
			{
				name: "admin.products.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/products/AdminProductsEdit.vue"),
				meta: {
					permissions: permissions.PRODUCTS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.offers.index",
		path: "offers",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.offers.list",
		},
		meta: {
			permissions: permissions.OFFERS_MANAGE,
		},
		children: [
			{
				name: "admin.offers.list",
				path: "list",
				component: () => import("@/views/pages/admin/offers/AdminOffersList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "admin.mainPageBlocks.index",
		path: "main-page-blocks",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.mainPageBlocks.list",
		},
		meta: {
			permissions: permissions.PAGES_PREVIEW,
		},
		children: [
			{
				name: "admin.mainPageBlocks.list",
				path: "list",
				component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksList.vue"),
				permissions: permissions.PAGES_PREVIEW,
			},
			{
				name: "admin.mainPageBlocks.brands",
				path: "brands",
				component: () => import("@/views/Blank.vue"),
				permissions: permissions.PAGES_MANAGE,
				redirect: {
					name: "admin.mainPageBlocks.brands.add",
				},
				children: [
					{
						name: "admin.mainPageBlocks.brands.add",
						path: "add",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksBrandsAdd.vue"),
					},
					{
						name: "admin.mainPageBlocks.brands.edit",
						path: "edit/:id?",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksBrandsEdit.vue"),
						meta: {
							menuRoute: {
								hidden: true,
							},
						},
					},
				],
			},
			{
				name: "admin.mainPageBlocks.shops",
				path: "shops",
				component: () => import("@/views/Blank.vue"),
				permissions: permissions.PAGES_MANAGE,
				redirect: {
					name: "admin.mainPageBlocks.shops.add",
				},
				children: [
					{
						name: "admin.mainPageBlocks.shops.add",
						path: "add",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksShopsAdd.vue"),
					},
					{
						name: "admin.mainPageBlocks.shops.edit",
						path: "edit/:id?",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksShopsEdit.vue"),
						meta: {
							menuRoute: {
								hidden: true,
							},
						},
					},
				],
			},
			{
				name: "admin.mainPageBlocks.offer",
				path: "offer",
				component: () => import("@/views/Blank.vue"),
				permissions: permissions.PAGES_MANAGE,
				redirect: {
					name: "admin.mainPageBlocks.offer.add",
				},
				children: [
					{
						name: "admin.mainPageBlocks.offer.add",
						path: "add",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksOffersAdd.vue"),
					},
					{
						name: "admin.mainPageBlocks.offer.edit",
						path: "edit/:id?",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksOffersEdit.vue"),
						meta: {
							menuRoute: {
								hidden: true,
							},
						},
					},
				],
			},
			{
				name: "admin.mainPageBlocks.banner",
				path: "banner",
				component: () => import("@/views/Blank.vue"),
				permissions: permissions.PAGES_MANAGE,
				redirect: {
					name: "admin.mainPageBlocks.banner.add",
				},
				children: [
					{
						name: "admin.mainPageBlocks.banner.add",
						path: "add",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksBannerAdd.vue"),
					},
					{
						name: "admin.mainPageBlocks.banner.edit",
						path: "edit/:id?",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksBannerEdit.vue"),
						meta: {
							menuRoute: {
								hidden: true,
							},
						},
					},
				],
			},
			{
				name: "admin.mainPageBlocks.html",
				path: "html",
				component: () => import("@/views/Blank.vue"),
				permissions: permissions.PAGES_MANAGE,
				redirect: {
					name: "admin.mainPageBlocks.html.add",
				},
				children: [
					{
						name: "admin.mainPageBlocks.html.add",
						path: "add",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksHtmlAdd.vue"),
					},
					{
						name: "admin.mainPageBlocks.html.edit",
						path: "edit/:id?",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksHtmlEdit.vue"),
						meta: {
							menuRoute: {
								hidden: true,
							},
						},
					},
				],
			},
			{
				name: "admin.mainPageBlocks.promotions",
				path: "promotions",
				component: () => import("@/views/Blank.vue"),
				permissions: permissions.PAGES_MANAGE,
				redirect: {
					name: "admin.mainPageBlocks.promotions.add",
				},
				children: [
					{
						name: "admin.mainPageBlocks.promotions.add",
						path: "add",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksPromotionsAdd.vue"),
						children: [],
					},
					{
						name: "admin.mainPageBlocks.promotions.edit",
						path: "edit/:id?",
						component: () => import("@/views/pages/admin/main_page_blocks/AdminMainPageBlocksPromotionsEdit.vue"),
						meta: {
							menuRoute: {
								hidden: true,
							},
						},
					},
				],
			},
		],
	},
	{
		name: "admin.seoDesc.index",
		path: "seo-descriptions",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.seoDesc.list",
		},
		meta: {
			permissions: permissions.SEO_DESCRIPTIONS_MANAGE,
		},
		children: [
			{
				name: "admin.seoDesc.list",
				path: "list",
				component: () => import("@/views/pages/admin/seo_descriptions/AdminSeoDescriptionsList.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.seoDesc.add",
				path: "add",
				component: () => import("@/views/pages/admin/seo_descriptions/AdminSeoDescriptionsAdd.vue"),
				meta: {
					permissions: permissions.SEO_DESCRIPTIONS_MANAGE,
				},
			},
			{
				name: "admin.seoDesc.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/admin/seo_descriptions/AdminSeoDescriptionsEdit.vue"),
				meta: {
					permissions: permissions.SEO_DESCRIPTIONS_MANAGE,
					menuRoute: {
						hidden: true,
					},
				},
			},
		],
	},
];

export default adminRoutes;

export const shopLegalForms = {
	onePersonBusiness: "one_person_business",
	civilPartnership: "civil_partnership",
	generalPartnership: "general_partnership",
	partnerCompany: "partner_company",
	limitedPartnership: "limited_partnership",
	limitedJointStockPartnership: "limited_joint_stock_partnership",
	limitedLiabilityCompany: "limited_liability_company",
	jointStockCompany: "joint_stock_company",
	other: "other"
}
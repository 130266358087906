export const shopShippingTimes = {
	"to_24_hours": "to_24_hours",
	"to_48_hours": "to_48_hours",
	"1_or_2_days": "1_or_2_days",
	"3_days": "3_days",
	"4_days": "4_days",
	"5_days": "5_days",
	"7_days": "7_days",
	"10_days": "10_days",
	"14_days": "14_days",
	"21_days": "21_days",
	"30_days": "30_days",
	"over_30_days": "over_30_days"
}
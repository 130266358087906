export const routes = {
	index: "index",

	home: "home",

	// ADMIN
	admin: "admin",

	// SHOP
	shop: "shop",
	help: "shop.help",
	shopVerification: "shop.verification",
	shopIntro: "shop.intro",

	// CUSTOMER
	customer: "customer.myEbut",

	// GUEST
	offersRoute: {
		name: "offers",
		path: "buty",
	},
	offers: "offers.list",
	offersPreview: "offers.preview",
	shops: "shops",
	shopsPreview: "shops.preview",
	brands: "brands",
	sizesChart: "sizesChart",

	pageStatic: "page.static",

	login: "auth.login",
	logout: "auth.logout",
	register: "auth.register",

	error403: "error.403",
	error404: "error.404",

	staticPage: {
		regulations: "regulations",
		privacyPolicy: "polityka-prywatnosci",
	},
};

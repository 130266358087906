export const permissions = {
	USERS_PREVIEW: "users.preview",
	USERS_MANAGE: "users.manage",
	ROLES_PREVIEW: "roles.preview",
	ROLES_MANAGE: "roles.manage",
	FILES_PREVIEW: "files.preview",
	FILES_MANAGE: "files.manage",
	SETTINGS_PREVIEW: "settings.preview",
	SETTINGS_MANAGE: "settings.manage",
	SHOP_USERS_PREVIEW: "shop_users.preview",
	SHOP_USERS_MANAGE: "shop_users.manage",
	PAGES_PREVIEW: "pages.preview",
	PAGES_MANAGE: "pages.manage",
	SHOPS_PREVIEW: "shops.preview",
	SHOPS_MANAGE: "shops.manage",
	PRODUCTS_PREVIEW: "products.preview",
	PRODUCTS_MANAGE: "products.manage",
	PRODUCT_CATEGORIES_MANAGE: "product_categories.manage",
	PRODUCT_CATEGORIES_PREVIEW: "product_categories.preview",
	BRANDS_PREVIEW: "brands.preview",
	BRANDS_MANAGE: "brands.manage",
	PAYMENT_METHODS_PREVIEW: "payment_methods.preview",
	PAYMENT_METHODS_MANAGE: "payment_methods.manage",
	SALES_PREVIEW: "sales.preview",
	SALES_MANAGE: "sales.manage",
	ADMIN_SALES_MANAGE: "admin.sales.manage",
	OFFERS_MANAGE: "offers.manage",
	SEO_DESCRIPTIONS_MANAGE: "seo_descriptions.manage",
	SEO_DESCRIPTIONS_PREVIEW: "seo_descriptions.preview",

	//CUSTOMER PERMISSIONS
	HAS_CUSTOMER_AGREEMENTS: "has_customer_agreements",
	CUSTOMER_PROFILE_PREVIEW: "customer_profile_preview",
	CUSTOMER_PRODUCT_ALERT_PREVIEW: "customer_product_alert.preview",
	CUSTOMER_PRODUCT_ALERT_MANAGE: "customer_product_alert.manage",

	//SHOP PERMISSIONS
	HAS_SHOP_AGREEMENTS: "has_shop_agreements",
	SHOP_OWNER_PREVIEW: "shop_owner.preview",
	SHOP_OWNER_MANAGE: "shop_owner.manage",
	SHOP_OFFERS_PREVIEW: "shop_offers.preview",
	SHOP_OFFERS_MANAGE: "shop_offers.manage",
};

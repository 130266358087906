import config from "@/config";

const permissionsKey = 'permissions';

export const permissions = config.permissions;

export const getUserPermissions = () => {
	return JSON.parse(window.localStorage.getItem(permissionsKey));
};

export const savePermissions = permissions => {
	window.localStorage.setItem(permissionsKey, JSON.stringify(permissions));
};

export const destroyPermissions = () => {
	window.localStorage.removeItem(permissionsKey);
};

export const hasPermission = permission => {
	if (!getUserPermissions()) {
		return false;
	}

	return getUserPermissions().includes(permission);
};

export const hasPermissions = permissions => {
	if (typeof permissions == 'string')
		permissions = [permissions];

	return permissions.reduce((result, permission) => {
		result &= hasPermission(permission);
		return result;
	}, true);
}

export default {permissions, getUserPermissions, savePermissions, destroyPermissions, hasPermission, hasPermissions};

import LocaleService from "@/services/LocaleService";
import PermissionsService from "@/services/PermissionsService";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";

export const PermissionsMiddleware = ({to, from, next, permissions}) => {
	if (!permissions) {
		return next();
	}

	if (typeof permissions == 'string') {
		permissions = [permissions];
	}
	if (permissions.length && !PermissionsService.hasPermissions(permissions)) {
		return RouterService.push({ name: RoutesService.routes.error403, params: { locale: LocaleService.getLocale() } });
	}

	return next();
}

export default PermissionsMiddleware;
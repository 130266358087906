import ShopVerifyFailedMiddleware from "@/middlewares/ShopVerifyFailedMiddleware";
import ShopVerifyPassedMiddleware from "@/middlewares/ShopVerifyPassedMiddleware";
import ShopIntroPassedMiddleware from "@/middlewares/ShopIntroPassedMiddleware";
import { permissions } from "@/services/PermissionsService";

const shopRoutes = [
	{
		name: "shop.verification",
		path: "verification",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [ShopVerifyFailedMiddleware],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "shop.verification.info",
		},
		children: [
			{
				name: "shop.verification.info",
				path: "info",
				component: () => import("@/views/pages/shop/verification/ShopVerification.vue"),
				meta: {
					menuTopTwoColsContentLayout: {
						aside: false,
					},
				},
			},
			{
				name: "shop.verification.account",
				path: "account",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "shop.verification.account.profile",
				},
				children: [
					{
						name: "shop.verification.account.profile",
						path: "profile",
						component: () => import("@/views/pages/shop/account/ShopAccount.vue"),
					},
					{
						name: "shop.verification.account.changePassword",
						path: "change-password",
						component: () => import("@/views/pages/shop/account/ShopAccountChangePassword.vue"),
					},
				],
			},
			{
				name: "shop.verification.sale",
				path: "sale",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "shop.verification.sale.shipping",
				},
				children: [
					{
						name: "shop.verification.sale.shipping",
						path: "shipping",
						component: () => import("@/views/pages/shop/sale/shipping/ShopSaleShipping.vue"),
					},
					{
						name: "shop.verification.sale.returns",
						path: "returns",
						component: () => import("@/views/pages/shop/sale/returns/ShopSaleReturns.vue"),
					},
				],
			},
		],
	},
	{
		name: "shop.index2",
		path: "",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: permissions.SHOP_OWNER_MANAGE,
			middleware: [ShopVerifyPassedMiddleware, ShopIntroPassedMiddleware],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "shop.myEbut",
		},
		children: [
			{
				name: "shop.help",
				path: "help",
				component: () => import("@/views/pages/shop/help/ShopHelp.vue"),
				meta: {
					menuTopTwoColsContentLayout: {
						aside: false,
					},
					menuRoute: {
						hidden: true,
					},
				},
			},
			{
				name: "shop.myEbut",
				path: "my-ebut",
				component: () => import("@/views/pages/shop/my_ebut/ShopMyEbut.vue"),
				meta: {
					layout: "default-shop-compact",
					menuTopTwoColsContentLayout: {
						aside: false,
					},
				},
			},
			{
				name: "shop.account",
				path: "account",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "shop.account.profile",
				},
				children: [
					{
						name: "shop.account.profile",
						path: "profile",
						component: () => import("@/views/pages/shop/account/ShopAccount.vue"),
					},
					{
						name: "shop.account.administration",
						path: "administration",
						component: () => import("@/views/pages/shop/account/ShopAccountAdministration.vue"),
					},
					{
						name: "shop.account.changePassword",
						path: "change-password",
						component: () => import("@/views/pages/shop/account/ShopAccountChangePassword.vue"),
					},
					{
						name: "shop.account.notifications",
						path: "notifications",
						component: () => import("@/views/pages/shop/account/ShopAccountNotifications.vue"),
					},
					{
						name: "shop.account.delete",
						path: "delete",
						component: () => import("@/views/pages/shop/account/ShopAccountDelete.vue"),
					},
				],
			},
			{
				name: "shop.sale",
				path: "sale",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "shop.sale.shipping",
				},
				children: [
					{
						name: "shop.sale.shipping",
						path: "shipping",
						component: () => import("@/views/pages/shop/sale/shipping/ShopSaleShipping.vue"),
					},
					{
						name: "shop.sale.returns",
						path: "returns",
						component: () => import("@/views/pages/shop/sale/returns/ShopSaleReturns.vue"),
					},
				],
			},
			{
				name: "shop.warehouse",
				path: "warehouse",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "shop.warehouse.list",
				},
				meta: {
					permissions: permissions.SHOP_OFFERS_PREVIEW,
				},
				children: [
					{
						name: "shop.warehouse.list.redirect",
						path: "list",
						redirect: {
							name: "shop.warehouse.list",
						},
					},
					{
						name: "shop.warehouse.add",
						path: "add",
						component: () => import("@/views/pages/shop/warehouse/ShopWarehouseAdd.vue"),
						meta: {
							permissions: permissions.SHOP_OFFERS_MANAGE,
						},
					},
					{
						name: "shop.warehouse.edit",
						path: "edit/:id",
						component: () => import("@/views/pages/shop/warehouse/ShopWarehouseEdit.vue"),
						meta: {
							permissions: permissions.SHOP_OFFERS_MANAGE,
							menuRoute: {
								hidden: true,
							},
							breadcrumbs: {
								hidden: true,
							},
						},
					},
				],
			},
			{
				name: "shop.promotions",
				path: "promotions",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "shop.promotions.list",
				},
				meta: {
					permissions: permissions.SHOP_OWNER_MANAGE,
				},
				children: [
					// {
					// 	name: "shop.sale.promotions.list",
					// 	path: "list",
					// 	component: () => import("@/views/pages/shop/sale/promotions/ShopSalePromotionsList.vue"),
					// 	meta: {
					// 		breadcrumbs: {
					// 			hidden: true,
					// 		},
					// 		menuRoute: {
					// 			hidden: true,
					// 		},
					// 	},
					// },
					{
						name: "shop.promotions.list.redirect",
						path: "list",
						redirect: {
							name: "shop.promotions.list",
						},
					},
					{
						name: "shop.promotions.discount",
						path: "discount",
						component: () => import("@/views/Blank.vue"),
						redirect: {
							name: "shop.promotions.discount.add",
						},
						children: [
							{
								name: "shop.promotions.discount.add",
								path: "add",
								component: () => import("@/views/pages/shop/promotions/discount/ShopPromotionsDiscountAdd.vue"),
								meta: {
									menuRoute: {
										hidden: true,
									},
								},
							},
							{
								name: "shop.promotions.discount.edit",
								path: "edit/:id",
								component: () => import("@/views/pages/shop/promotions/discount/ShopPromotionsDiscountEdit.vue"),
								meta: {
									menuRoute: {
										hidden: true,
									},
								},
							},
						],
					},
				],
			},
			{
				name: "shop.analytics",
				path: "analytics",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "shop.analytics.businessCard",
				},
				children: [
					{
						name: "shop.analytics.businessCard",
						path: "business-card",
						component: () => import("@/views/pages/shop/analytics/ShopAnalyticsBusinessCard.vue"),
					},
					{
						name: "shop.analytics.summary",
						path: "summary",
						component: () => import("@/views/pages/shop/analytics/ShopAnalyticsSummary.vue"),
					},
					{
						name: "shop.analytics.offers",
						path: "offers",
						component: () => import("@/views/pages/shop/analytics/ShopAnalyticsOffers.vue"),
					},
				],
			},
		],
	},
];

export default shopRoutes;

export const shopShippingCarriers = {
	dpd: 'dpd',
	ups: 'ups',
	gls: 'gls',
	dhl: 'dhl',
	fedex: 'fedex',
	inpost: 'inpost',
	inpostLocker: 'inpostLocker',
	pocztex24: 'pocztex24',
	pocztex48: 'pocztex48',
	tnt: 'tnt',
	courier: 'courier',
	polishPostEconomic: 'polishPostEconomic',
	polishPostPriority: 'polishPostPriority',
	letterRegistered: 'letterRegistered',
	letter: 'letter'
}
import { defineStore } from "pinia";
import SeoDescriptionRepository from "@/repositories/SeoDescriptionRepository";

export const useSeoDescriptionStore = defineStore("SeoDescription", {
	state: () => ({
		url: {},
	}),

	getters: {
		getDataByUrl: (state) => {
			return (url) => {
				if (!url) return null;
				if (!(url in state.url)) return null;

				return state.url[url];
			};
		},
		getMetaByUrl: (state) => {
			return (url) => {
				if (!url) return {};
				if (!(url in state.url)) return {};

				const urlData = state.url[url];

				const meta = {};

				if ("title_tag" in urlData && !!urlData.title_tag) meta.title = urlData.title_tag;
				if ("description_tag" in urlData && !!urlData.description_tag) meta.description = urlData.description_tag;

				return meta;
			};
		},
		getH1ByUrl: (state) => {
			return (url) => {
				if (!url) return null;
				if (!(url in state.url)) return null;

				const urlData = state.url[url];

				if ("level_one_heading" in urlData && !!urlData.level_one_heading) return urlData.level_one_heading;

				return null;
			};
		},
		getContentByUrl: (state) => {
			return (url) => {
				if (!url) return null;
				if (!(url in state.url)) return null;

				const urlData = state.url[url];

				const content = {};

				if ("section_title" in urlData && !!urlData.section_title) content.sectionTitle = urlData.section_title;
				if ("section_description" in urlData && !!urlData.section_description) content.sectionDescription = urlData.section_description;
				if ("file" in urlData && !!urlData.file_id) content.imageUrl = urlData.file.download_url;
				if ("file_title_tag" in urlData && !!urlData.file_title_tag) content.fileTitleTag = urlData.file_title_tag;
				if ("file_description_tag" in urlData && !!urlData.file_description_tag) content.fileDescriptionTag = urlData.file_description_tag;

				if (_.isEmpty(content)) return null;

				return content;
			};
		},
	},

	actions: {
		fetchByUrl(url) {
			let theUrl = url;

			theUrl = encodeURIComponent(theUrl);

			return new Promise((resolve, reject) => {
				SeoDescriptionRepository.getByUrl(theUrl)
					.then((result) => {
						this.url[url] = result.data.data;
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
	},
});

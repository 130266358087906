export const mainPageBlocks = {
	types: {
		offerBlock: 'offer_block',
		brandBlock: 'brand_block',
		htmlBlock: 'html_block',
		promotionsBlock: 'promotions_block',
		shopBlock: 'shop_block',
		bannerBlock: 'banner_block'
	},
	layouts: {
		oneByThree: '1x3',
		twoByFour: '2x4'
	},
	colorPallets: {
		light: '0',
		dark: '1'
	},
	promotionSourceTypes: {
		allPromotions: 'all_promotions',
		shopPromotions: 'shop_promotions',
		chosenPromotions: 'chosen_promotions'
	}
}


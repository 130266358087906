export const currencies = [
	{
		id: "pln",
		slug: "pln",
		slugLocal: "zł",
		default: true,
		name: {
			pl: "Polski Złoty",
			en: "Polish Zloty",
		},
	},
];

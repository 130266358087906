import RepositoryService from "@/services/RepositoryService";
const resource = "seo-description";

export const getByUrl = (url) => {
	const params = {
		url: url,
	};

	return RepositoryService.getRaw(`${resource}`, params);
};

export default { getByUrl };

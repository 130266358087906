import {useAuthStore} from "@/stores/useAuthStore";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";
import RolesService from "@/services/RolesService";
import ShopsStatusService from "@/services/ShopsStatusService";
import LocaleService from "@/services/LocaleService";

export const ShopVerifyFailedMiddleware = ({to, next}) => {
	const locale = to.params.locale? to.params.locale : LocaleService.getLocale();

	if (!RolesService.isShop()) {
		return RouterService.push({ name: RoutesService.routes.home, params: { locale: locale } });
	}

	if (useAuthStore().getUser.shop.status !== ShopsStatusService.shopsStatus.STATUS_URL_IS_NOT_VERIFIED) {
		return RouterService.push({ name: RoutesService.routes.shop, params: { locale: locale } });
	}

	return next();
}

export default ShopVerifyFailedMiddleware;
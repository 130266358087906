export const promotions = {
	types: {
		discount: "discount",
		// giveaway: 'giveaway',
		// contest: 'contest'
	},

	discount: {
		forms: {
			amount: "amount",
			percent: "percent",
			freeShipping: "free_shipping",
			other: "other",
		},
		assortmentTypes: {
			all: "all",
			categories: "categories",
		},
	},

	statuses: {
		draft: "draft",
		planned: "planned",
		finished: "finished",
		active: "active",
		inactive: "inactive",
	},
};

import { defineStore } from "pinia";
import AuthRepository from "@/repositories/AuthRepository";
import PermissionsService from "@/services/PermissionsService";

export const useAuthStore = defineStore("auth", {
	state: () => ({
		user: null,
		notifications: null,
		appVersion: null,
	}),

	getters: {
		getUser(state) {
			return state.user;
		},
		getNotifications(state) {
			return state.notifications;
		},
		getUserRoleId(state) {
			if (!state.user) return false;

			return state.user.role_id;
		},
		getIsAuthenticated(state) {
			return state.user !== null;
		},
		getUserBrands(state) {
			return state.user.brands;
		},
		getAppVersion(state) {
			return state.appVersion;
		},
	},

	actions: {
		check() {
			return new Promise((resolve, reject) => {
				AuthRepository.check()
					.then((result) => {
						this.updateUser(result.data.data);
						resolve(result);
					})
					.catch((result) => {
						this.clearUser();
						reject(result);
					});
			});
		},
		login(credentials) {
			return new Promise((resolve, reject) => {
				AuthRepository.login(credentials)
					.then((result) => {
						this.updateUser(result.data.data);
						resolve(result);
					})
					.catch((result) => {
						this.clearUser();
						reject(result);
					});
			});
		},
		logout() {
			return new Promise((resolve, reject) => {
				AuthRepository.logout()
					.then((result) => {
						this.clearUser();
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		passwordForgot(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.passwordForgot(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		passwordReset(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.passwordReset(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		update(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.update(data)
					.then((result) => {
						this.updateUser(result.data.data);
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		fetchNotifications() {
			return new Promise((resolve, reject) => {
				AuthRepository.getNotifications()
					.then((result) => {
						this.notifications = result.data.data.settings;
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		updateNotifications(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.updateNotifications(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		updateUser(user) {
			this.user = user;
			this.appVersion = user.app ? user.app.version : null;
			PermissionsService.savePermissions(user.permissions);
		},
		clearUser() {
			this.$reset();
			PermissionsService.destroyPermissions();
		},
		deleteAccount() {
			return new Promise((resolve, reject) => {
				AuthRepository.deleteAccount()
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
	},
});

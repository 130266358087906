import config from "@/config";

const currencies = config.currencies;
const currencyKey = "currency";

export const getCurrencies = () => {
	return currencies;
};

export const getDefaultCurrency = () => {
	return getCurrencies().reduce((result, element) => (element.default ? element : result), {});
};

export const getCurrencyBySlug = (slug) => {
	return getCurrencies().reduce((result, element) => (element.slug === slug ? element : result), {});
};

export const getCurrentCurrency = () => {
	return window.localStorage.getItem(currencyKey);
};

export const getCurrency = () => {
	if (hasCurrency()) {
		return getCurrentCurrency();
	}
	return getDefaultCurrency().slug;
};

export const getCurrencyObject = () => {
	return getCurrencyBySlug(getCurrency());
};

export const saveCurrency = (currency) => {
	if (typeof currency === "object") {
		currency = currency.slug;
	}
	if (!exists(currency)) {
		return false;
	}
	window.localStorage.setItem(currencyKey, currency);
};

export const destroyCurrency = () => {
	window.localStorage.removeItem(currencyKey);
};

export const hasCurrency = () => {
	return null !== getCurrentCurrency() && exists(getCurrentCurrency());
};

export const exists = (currency) => {
	return !!getCurrencies()
		.map((currency) => currency.slug)
		.includes(currency);
};

export default {
	getCurrencies,
	getDefaultCurrency,
	getCurrencyBySlug,
	getCurrentCurrency,
	getCurrency,
	getCurrencyObject,
	saveCurrency,
	destroyCurrency,
	hasCurrency,
	exists,
};

import config from "@/config";
import {useAuthStore} from "@/stores/useAuthStore";

export const roles = config.roles

const hasRole = (role, user) => {
	if (!role || !user) {
		return false;
	}

	const userRoleId = (typeof user === 'object'? user.role_id : user);

	return '' + role === '' + userRoleId;
}

const getCurrentUser = () => {
	const authStore = useAuthStore();

	return authStore.getUser;
}

export const isAdmin = (user = null) => {
	if (!user) {
		user = getCurrentUser();
	}
	return hasRole(roles.admin, user);
}

export const isCustomer = (user = null) => {
	if (!user) {
		user = getCurrentUser();
	}
	return hasRole(roles.customer, user);
}

export const isShop = (user = null) => {
	if (!user) {
		user = getCurrentUser();
	}
	return hasRole(roles.shop, user);
}

export default { roles, isAdmin, isCustomer, isShop }
import { mixed, string, number, array, addMethod } from "yup";

const isNullOrUndefined = function (value) {
	return value === null || value === undefined;
};

const stringToNumber = (string) => {
	if (!string) return 0;
	if (typeof string == "number") return string;

	return +string.replace(/\D+/g, "");
};

const sizeToBytes = (size) => {
	if (!size) return;
	if (typeof size == "number") return size;

	const sizeToCheck = size.toLowerCase().trim();

	let multiplier = 1;

	if (sizeToCheck.endsWith("kb") || sizeToCheck.endsWith("k")) multiplier = 1000;
	if (sizeToCheck.endsWith("mb") || sizeToCheck.endsWith("m")) multiplier = 1000000;
	if (sizeToCheck.endsWith("gb") || sizeToCheck.endsWith("g")) multiplier = 1000000000;

	const sizeAsNumber = stringToNumber(size);

	return sizeAsNumber * multiplier;
};

function empty() {
	return this.nullable().transform((value, originalValue) => {
		if (this.isType(value)) return value;

		return String(originalValue).trim() === "" ? null : value;
	});
}
addMethod(mixed, "empty", empty);
addMethod(string, "empty", empty);
addMethod(number, "empty", empty);
addMethod(array, "empty", empty);

function numberWithComma() {
	return this.transform((value, originalValue) => {
		if (originalValue === null) return value;
		if (!originalValue) return value;
		return Number(String(originalValue).replace(/,/, "."));
	});
}
addMethod(number, "numberWithComma", numberWithComma);

function fileSizeMax(size = "", message) {
	const msg = message || 'Max file size for field "${path}" is "${size}"';
	return this.test({
		name: "fileSizeMax",
		exclusive: true,
		message: msg,
		params: { size },
		test(value) {
			if (isNullOrUndefined(value)) return true;
			if (!Array.isArray(value)) return true;
			if (!value.length) return true;

			const sizeInBytes = sizeToBytes(size);

			return value.reduce((result, element) => {
				if (!("size" in element)) return result;
				result &= element.size <= sizeInBytes;
				return result;
			}, true);
		},
	});
}
addMethod(mixed, "fileSizeMax", fileSizeMax);

function fileSizeMin(size = "", message) {
	const msg = message || 'Min file size for field "${path}" is "${size}"';
	return this.test({
		name: "fileSizeMin",
		exclusive: true,
		message: msg,
		params: { size },
		test(value) {
			if (isNullOrUndefined(value)) return true;
			if (!Array.isArray(value)) return true;
			if (!value.length) return true;

			const sizeInBytes = sizeToBytes(size);

			return value.reduce((result, element) => {
				if (!("size" in element)) return result;
				result &= element.size >= sizeInBytes;
				return result;
			}, true);
		},
	});
}
addMethod(mixed, "fileSizeMin", fileSizeMin);

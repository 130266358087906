export const offers = {
	statuses: {
		DRAFT: "draft", // SZKIC
		NOT_ACTIVE: "not_active", // WYŁĄCZONY
		ACTIVE: "active", // OPUBLIKOWANY - AKTYWNY
		WAITING_FOR_ACCEPT: "waiting_for_accept", // OCZEKUJE NA AKCEPTACJĘ
		ERRORS: "errors", // BŁĘDY
		OUT_OF_DATA: "out_of_date", // NIEAKTUALNY
		SHOP_NOT_ACTIVE: "shop_not_active", // SKLEP nie jest aktywny lub niezweryfikowany
	},

	labels: {
		NEW: "new",
		SHIPPING_FREE: "shipping_free",
		// SALE: "sale",
	},
};

import config from "@/config";
import LocaleService from "@/services/LocaleService";
import RolesService from "@/services/RolesService";
import { useAuthStore } from "@/stores/useAuthStore";

export const routes = config.routes;

export const staticPageRoute = (slug) => {
	if (!slug) {
		return false;
	}

	return `/${LocaleService.getLocale()}/${slug}`;
};

export const staticPageRegulationsRoute = () => {
	if (!routes.staticPage) {
		return false;
	}
	if (!routes.staticPage.regulations) {
		return false;
	}

	return staticPageRoute(routes.staticPage.regulations);
};

export const staticPagePrivacyPolicyRoute = () => {
	if (!routes.staticPage) {
		return false;
	}
	if (!routes.staticPage.privacyPolicy) {
		return false;
	}

	return staticPageRoute(routes.staticPage.privacyPolicy);
};

export const routeTo = (name, params = {}, query = {}, hash = "") => {
	if (!name) return null;

	if (!params) params = {};

	return {
		name: name,
		params: {
			...{ locale: LocaleService.getLocale() },
			...params,
		},
		query: query,
		hash: hash,
	};
};

export const offersRouteTo = (query = null, params = null) => {
	if (!routes.offers) {
		return false;
	}

	return routeTo(routes.offers, params, query);
};

export const offersPreviewRouteTo = (offerSlug = null, hash = "") => {
	if (!routes.offersPreview || !offerSlug) return false;

	return routeTo(
		routes.offersPreview,
		{
			offerSlug: offerSlug,
		},
		null,
		hash
	);
};

export const shopsRouteTo = (query = null) => {
	if (!routes.shops) {
		return false;
	}

	return routeTo(routes.shops, null, query);
};

export const shopsPreviewRouteTo = (shopSlug = null, hash = "") => {
	if (!routes.shopsPreview || !shopSlug) return false;

	return routeTo(
		routes.shopsPreview,
		{
			shopSlug: shopSlug,
		},
		null,
		hash
	);
};

export const brandsRouteTo = (query = null) => {
	if (!routes.brands) {
		return false;
	}

	return routeTo(routes.brands, null, query);
};

export const sizesChartRouteTo = (sizesChartId = null) => {
	if (!routes.sizesChart || !sizesChartId) return false;

	return routeTo(routes.sizesChart, {
		sizesChartId: sizesChartId,
	});
};

export const defaultRoute = () => {
	const authStore = useAuthStore();
	switch (authStore.getUserRoleId) {
		case RolesService.roles.admin:
			return routes.admin;
			break;
		case RolesService.roles.shop:
			return routes.shop;
			break;
		case RolesService.roles.customer:
			return routes.customer;
			break;
		default:
			return routes.home;
			break;
	}

	return "";
};

export default {
	routes,
	staticPageRoute,
	staticPageRegulationsRoute,
	staticPagePrivacyPolicyRoute,
	routeTo,
	offersRouteTo,
	offersPreviewRouteTo,
	shopsRouteTo,
	shopsPreviewRouteTo,
	brandsRouteTo,
	sizesChartRouteTo,
	defaultRoute,
};

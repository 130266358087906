import { ref } from "vue";
import { useHead } from "@unhead/vue";

const head = ref(null);

export default () => {
	const setCanonical = (href = null) => {
		disposeCanonical();

		head.value = useHead({
			link: [{ rel: "canonical", href: href }],
		});
	};

	const disposeCanonical = () => {
		if (!head.value) return false;

		head.value.dispose();
	};

	return { setCanonical, disposeCanonical };
};

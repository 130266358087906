export const otherPagesContent = {
	shopRegistrationForm: "shop_registration_form",
	customerRegistrationForm: "customer_registration_form",
	shopRegistrationFormRegulationsAndPrivacyPolicy: "shop_registration_form_regulations_and_privacy_policy",
	customerRegistrationFormRegulationsAndPrivacyPolicy: "customer_registration_form_regulations_and_privacy_policy",
	shopRegistrationFormMarketingAgreement1: "shop_registration_form_marketing_agreement_1",
	customerRegistrationFormMarketingAgreement1: "customer_registration_form_marketing_agreement_1",
	registrationFormRodo: "registration_form_rodo_info",
	shopVerificationInfo: "shop_verification_info",
	cookie: "cookie_info",
	regulations: "regulations_html",
	privacyPolicy: "privacy_policy_html",
	brands: "brands_html",
	alertSize: "alert_size_info",
	alertPrice: "alert_price_info",
	alertPricePersonalDataAdministrator: "alert_price_personal_data_administrator",
	alertSizePersonalDataAdministrator: "alert_size_personal_data_administrator",
	customerOfferPriceSaleConditionsInfo: "customer_offer_price_sale_conditions_info",
	shopOfferPriceSaleInfo: "shop_offer_price_sale_info",
};

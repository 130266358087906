import { permissions } from "@/services/PermissionsService";

const customerRoutes = [
	{
		name: "customer.index2",
		path: "",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: permissions.CUSTOMER_PROFILE_PREVIEW,
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "customer.myEbut",
		},
		children: [
			{
				name: "customer.myEbut",
				path: "my-ebut",
				component: () => import("@/views/pages/customer/my_ebut/CustomerMyEbut.vue"),
				meta: {
					layout: "default-compact",
					menuTopTwoColsContentLayout: {
						aside: false,
					},
				},
			},
			{
				name: "customer.profile",
				path: "profile",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "customer.profile.edit",
				},
				children: [
					{
						name: "customer.profile.edit",
						path: "edit",
						component: () => import("@/views/pages/customer/profile/CustomerProfileEdit.vue"),
					},
					{
						name: "customer.profile.changePassword",
						path: "change-password",
						component: () => import("@/views/pages/customer/profile/CustomerProfileChangePassword.vue"),
					},
					{
						name: "customer.profile.favouriteBrands",
						path: "favourite-brands",
						component: () => import("@/views/pages/customer/profile/CustomerProfileFavouriteBrands.vue"),
					},
					{
						name: "customer.profile.genderAndSizes",
						path: "gender-and-sizes",
						component: () => import("@/views/pages/customer/profile/CustomerProfileGenderAndSizes.vue"),
					},
					{
						name: "customer.profile.administration",
						path: "administration",
						component: () => import("@/views/pages/customer/profile/CustomerProfileAdministration.vue"),
					},
					{
						name: "customer.profile.accountDelete",
						path: "delete-account",
						component: () => import("@/views/pages/customer/profile/CustomerProfileAccountDelete.vue"),
					},
				],
			},
		],
	},
];

export default customerRoutes;

export const slug = {
	type: {
		page: "page",
		productCategory: "productCategory",
		color: "color",
		upperMaterial: "upperMaterial",
		attributeValue: "attributeValue",
		season: "season",
		brand: "brand",
		fasten: "fasten",
		shop: "shop",
	},
};

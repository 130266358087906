export const menuPages = {
	menuTypes: {
		customerFooterColumnLeft: "customer_footer_column_left",
		customerFooterColumnInfo: "customer_footer_column_info",
		customerFooterColumnHelp: "customer_footer_column_help",
		customerFooterColumnFaq: "customer_footer_column_faq",
		shopFooterMenuRight: "shop_footer_menu_right",
	},
	pageTypes: {
		page: "page",
		external_url: "external_url",
	},
};

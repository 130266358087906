import { computed } from "vue";
import lang from "@/lang";
import { useSeoMeta } from "@unhead/vue";
import useHeadCanonical from "@/views/composables/head_canonical/useHeadCanonical";
import { useSeoDescriptionStore } from "@/stores/useSeoDescriptionStore";
import LocaleService from "@/services/LocaleService";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";

const seoLang = lang[LocaleService.getLocale()]["seo"];

const getDefault = () => {
	if (!("default" in seoLang)) return null;

	return seoLang.default;
};
const getTitleTemplate = () => {
	if (!("titleTemplate" in seoLang)) return null;

	return seoLang.titleTemplate;
};

const getRouteSEO = (routeName) => {
	if (!routeName) return null;

	if (!("routes" in seoLang)) return null;
	if (!(routeName in seoLang.routes)) return null;

	return { ...{ titleTemplate: getTitleTemplate() }, ...seoLang.routes[routeName] };
};

const getRouteH1 = computed(() => {
	const seoDescriptionStore = useSeoDescriptionStore();

	const to = RouterService.currentRoute.value;

	return seoDescriptionStore.getH1ByUrl(to.fullPath);
});

const getRouteContentData = computed(() => {
	const seoDescriptionStore = useSeoDescriptionStore();

	const to = RouterService.currentRoute.value;

	return seoDescriptionStore.getContentByUrl(to.fullPath);
});

const setMeta = (meta = null) => {
	if (!meta) return null;

	useSeoMeta(meta);
};

const setRouteMeta = (routeMeta) => {
	const seoDescriptionStore = useSeoDescriptionStore();

	const to = RouterService.currentRoute.value;

	const seoMeta = seoDescriptionStore.getMetaByUrl(to.fullPath);

	const meta = { ...routeMeta, ...seoMeta };

	setMeta(meta);
};

const getSeoDescription = (url) => {
	const seoDescriptionStore = useSeoDescriptionStore();

	return new Promise((resolve, reject) => {
		seoDescriptionStore
			.fetchByUrl(url)
			.then(() => {
				const result = seoDescriptionStore.getMetaByUrl(url);
				resolve(result);
			})
			.catch(() => {
				reject();
			});
	});
};

const setCanonicalForOffersList = (filters = null) => {
	const { setCanonical } = useHeadCanonical();

	if (!filters || !Array.isArray(filters) || !filters.length) {
		return true;
	}

	const baseParams = [LocaleService.getLocale(), RoutesService.routes.offersRoute.path];

	const filterParams = [];

	const filtersToCanonical = ["gender", "brand", "category", "shop"];

	filtersToCanonical.forEach((filterName) => {
		const value = filters.find((filter) => filter._filter.name.includes(filterName));

		if (value) filterParams.push(value.value);
	});

	const canonicalUrl = new URL(location.origin + "/" + baseParams.join("/") + (filterParams.length ? "/" + filterParams.join("/") : ""));

	setCanonical(canonicalUrl.href);
};

const setCanonicalSelf = () => {
	const { setCanonical } = useHeadCanonical();

	setCanonical(location.href);
};

const removeCanonical = () => {
	const { disposeCanonical } = useHeadCanonical();

	disposeCanonical();
};

const controller = async (to) => {
	if (!to) return null;
	if (!to.name) return null;

	let meta = {};

	const defaultSeoMeta = getDefault();

	const routeSeoMeta = getRouteSEO(to.name);

	meta = { ...meta, ...(routeSeoMeta ? routeSeoMeta : defaultSeoMeta) };

	await getSeoDescription(to.fullPath)
		.then((result) => {
			meta = { ...meta, ...result };
		})
		.catch(() => {});

	setMeta(meta);
};

export default {
	controller,
	setMeta,
	setRouteMeta,
	getRouteH1,
	getRouteContentData,
	getDefault,
	getTitleTemplate,
	getRouteSEO,
	setCanonicalForOffersList,
	setCanonicalSelf,
	removeCanonical,
};

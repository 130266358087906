import LocaleService from "@/services/LocaleService";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";

export const LocaleExistsMiddleware = ({ to, next }) => {
	if (to.params.locale && !LocaleService.exists(to.params.locale)) {
		to.meta["404"] = true;
		// return next({ name: RoutesService.routes.error404, params: { locale: LocaleService.getLocale() } });
		// return RouterService.push({ name: RoutesService.routes.error404 });
	}
	// alert("v");
	return next();
};

export default LocaleExistsMiddleware;

import AuthFailedMiddleware from "@/middlewares/AuthFailedMiddleware";
import AuthPassedMiddleware from "@/middlewares/AuthPassedMiddleware";
import LocaleService from "@/services/LocaleService";

const authRoutes = [
	{
		name: "auth.passed",
		path: "",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [AuthPassedMiddleware],
		},
		children: [
			{
				path: "",
				redirect: {
					name: "mainPage",
					params: {
						locale: LocaleService.getLocale(),
					},
				},
			},
			{
				name: "auth.logout",
				path: "wyloguj",
				component: () => import("@/views/pages/auth/AuthLogout.vue"),
			},
		],
	},
	{
		name: "auth.failed",
		path: "",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [AuthFailedMiddleware],
		},
		children: [
			{
				name: "auth.login",
				path: "logowanie",
				component: () => import("@/views/pages/auth/AuthLogin.vue"),
			},
			{
				name: "auth.register",
				path: "rejestracja",
				component: () => import("@/views/pages/auth/AuthRegister.vue"),
			},
			{
				path: "rejestracja/potwierdz",
				redirect: {
					name: "auth.login",
					params: {
						locale: LocaleService.getLocale(),
					},
				},
			},
			{
				name: "auth.register.confirm",
				path: "rejestracja/potwierdz/:id",
				component: () => import("@/views/pages/auth/AuthRegisterConfirm.vue"),
			},
			{
				name: "auth.password.forgot",
				path: "zapomniales-hasla",
				component: () => import("@/views/pages/auth/AuthPasswordForgot.vue"),
			},
			{
				name: "auth.password.reset",
				path: "resetuj-haslo",
				component: () => import("@/views/pages/auth/AuthPasswordReset.vue"),
			},
		],
	},
];

export default authRoutes;
